<template>
  <div>
    <h1>当前版本v1.0.0</h1>
    <h2>
      历史更新记录
    </h2>
    <div>
      <div>
        <h3>
          v1.0.0
        </h3>
        <p>2022年1月28日</p>
        <p>初始版本上线</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
